import React, { Component } from "react";
import $ from 'jquery';
import TopVideo from '../../assets/images/top-video.mov'
import BottomVideo from '../../assets/images/bottom-video.mov'

class Metaverse extends Component {
    constructor() {
        super();
        this.state = {
            src_path: window.src_path,
        }
    }


    loadJs() {
        /*  setTimeout(function() { $("#page-loader").fadeOut(1500); }, 3000); */
    }
    componentDidMount() {
        this.loadJs()
    }

    render() {
        return (
            <>
                <section class="video__sec meta__sec">
                    <video autoPlay loop muted src={TopVideo} type="video/mov" preLoad="metadata">
                    </video>
                    <a href="https://play.decentraland.org/?island=I3oq6l&position=84%2C-27&realm=loki" target="_blank" className="css_btn enter__meta">ENTER Decentraland</a>
                </section>
                <section class="video__sec meta__sec">
                    <video autoPlay loop muted src={BottomVideo} type="video/mov" preLoad="metadata">
                    </video>
                    <a href="https://oncyber.io/yvault" target="_blank" className="css_btn enter__meta rtl">ENTER OnCyber</a>
                </section>
            </>
        )
    }
}

export default Metaverse;