import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { Helmet } from "react-helmet";
import $ from 'jquery';

import Bg from '../../assets/images/abour.gif'
import Flamingo from '../../assets/images/flamnigo.png'
import Fingerprints from '../../assets/images/fingerprints.png'
import NeonDao from '../../assets/images/Neon-DAO.jpg'
import Tribute from '../../assets/images/tribute.png'
import RedDao from '../../assets/images/reddao.png'
import Muse from '../../assets/images/Muse0.png'
import Glimmer from '../../assets/images/partners/glimmerdao.png'
import NoiseDao from '../../assets/images/partners/noisedao-logo.png'

class About extends Component {
    src_path = window.src_path;
    constructor() {
        super();
        this.state = {
            src_path: window.src_path,
        }
    }
    clickMenu() {
        $("#page-loader").show();
        window.scrollTo(0, 0);
        setTimeout(function () { $("#page-loader").fadeOut(1500); }, 3000);
        $(".qodef-follow-info-holder").remove();
        $("body").removeClass('sidebar_active');
    }
    swiperLoad() {
        //console.log(window.homepageRoute);
    }

    componentDidMount() {
        this.swiperLoad()
    }

    render() {
        return (
            <>
                <section className="about__main">
                    <div className="about_header">
                        <div className="left__panel about__gf-bg">
                        </div>
                        <div className="right__panel">
                            <h2 className="heading styled">
                                Web3 is the internet owned by <em>communities</em> of users and builders, coordinated by <em>token</em> incentives
                            </h2>
                            <div className="desc">
                                <p>From time to time, paradigm shifting trends emerge to disrupt the status quo of the current business models. Web2 created the proliferation of platform based businesses where value accrued back to few institutions. And now, Web3 has inverted these economics back to fundamental value providers: users and builders.</p>
                                <p>At Kanosei, we invest in Web3 opportunities such as DAOs, NFTs, and DeFi protocols that are democratizing access and reshaping ownership on the internet.</p>
                            </div>
                            <div className="page_links">
                                <Link onClick={() => this.clickMenu()} to="/collection" className="css_btn"><span>NFT Art Collection</span></Link>
                                <Link onClick={() => this.clickMenu()} to="/investments" className="css_btn"><span>WEB3 INVESTMENTS</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="artist__info_sec">
                        <div className="wrapper">
                            <h2 className="heading">DAOS ARE COLLECTIVE HIVE MINDS COORDINATING <br />
                                SOCIAL & ECONOMIC CAPITAL</h2>
                            <p className="desc">DAOs we are involved in</p>
                            <div className="artist__info_main">
                                <div className="artist__info">
                                    <a href="https://twitter.com/FLAMINGODAO" target="_blank" className="artist__image"><img src={Flamingo} alt="" /></a>
                                    <div className="text">
                                        <h4 className="artist__title"><a href="https://twitter.com/FLAMINGODAO" target="_blank">  Flamingo DAO  </a></h4>
                                        <p className="artist__desc">A flamboyance of NFT collectors, curators, and artists</p></div>
                                </div>
                                <div className="artist__info">
                                    <a href="https://twitter.com/FingerprintsDAO" className="artist__image" target="_blank"><img src={Fingerprints} alt="" /></a>
                                    <div className="text">
                                        <h4 className="artist__title"><a href="https://twitter.com/FingerprintsDAO" target="_blank"> Fingerprints DAO </a></h4>
                                        <p className="desc">A collector and curator of blockchain fingerprints</p></div>
                                </div>
                                <div className="artist__info">
                                    <a href="https://twitter.com/neon_dao" className="artist__image" target="_blank"><img src={NeonDao} alt="" /></a>
                                    <div className="text">
                                        <h4 className="artist__title"><a href="https://twitter.com/neon_dao" target="_blank">  Neon DAO  </a></h4>
                                        <p className="artist__desc">A collective building out the metaverse</p></div>
                                </div>
                                <div className="artist__info">
                                    <a href="https://twitter.com/noisedao" className="artist__image" target="_blank"><img src={NoiseDao} alt="" /></a>
                                    <div className="text">
                                        <h4 className="artist__title"><a href="https://twitter.com/noisedao" target="_blank">  Noise DAO  </a></h4>
                                        <p className="artist__desc">A DAO supporting music NFTs and projects</p></div>
                                </div>
                                <div className="artist__info">
                                    <a href="https://twitter.com/RED___DAO" className="artist__image" target="_blank"><img src={RedDao} alt="" /></a>
                                    <div className="text">
                                        <h4 className="artist__title"><a href="https://twitter.com/RED___DAO" target="_blank"> Red DAO </a></h4>
                                        <p className="artist__desc">A digital fashion DAO house.</p></div>
                                </div>
                                <div className="artist__info">
                                    <a href="https://twitter.com/MUSE0DAO" className="artist__image" target="_blank"><img src={Muse} alt="" /></a>
                                    <div className="text">
                                        <h4 className="artist__title"><a href="https://twitter.com/MUSE0DAO" target="_blank"> Muse0 </a></h4>
                                        <p className="artist__desc">A museum DAO created by an alien</p></div>
                                </div>
                                <div className="artist__info">
                                    <a href="https://twitter.com/glimmerdao" className="artist__image" target="_blank"><img src={Glimmer} alt="" /></a>
                                    <div className="text">
                                        <h4 className="artist__title"><a href="https://twitter.com/glimmerdao" target="_blank">  Glimmer DAO  </a></h4>
                                        <p className="artist__desc">NFT investment DAO focused on Asia</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*        <!-- Swiper --> */}
                    {/* <div className="author__slider_main">
                        <div className="wrapper">
                            <svg className="author__quote__mark" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="74px" height="74px" viewBox="0 0 74 74" enableBackground="new 0 0 74 74" xmlSpace="preserve">
                                <g>
                                    <path fill="none" stroke="#6F7070" strokeDasharray="10,2" d="M32.883,18.138c2.336,2.276,3.504,5.332,3.504,9.165 c0,1.798-0.27,3.416-0.808,4.852c-0.54,1.439-1.348,3.177-2.426,5.213L21.829,59.112H10.147l7.549-21.386 c-3.595-2.396-5.392-5.869-5.392-10.424c0-3.713,1.137-6.739,3.414-9.077c2.275-2.334,5.15-3.503,8.627-3.503 C27.698,14.723,30.546,15.863,32.883,18.138z M61.457,18.138c2.338,2.276,3.506,5.332,3.506,9.165c0,1.798-0.27,3.416-0.81,4.852 c-0.539,1.439-1.348,3.177-2.426,5.213L50.406,59.112H38.724l7.548-21.386c-3.594-2.396-5.392-5.869-5.392-10.424 c0-3.713,1.138-6.739,3.415-9.077c2.274-2.334,5.15-3.503,8.627-3.503C56.274,14.723,59.121,15.863,61.457,18.138z"></path>
                                </g>
                            </svg>
                            <div className="swiper mySwiper author__slider">
                                <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <p className="text">“Community is the moat”</p>
                                    <span className="author__name">Coopatroopah</span>
                                    <span className="author__job"></span>
                                </div>
                                <div className="swiper-slide">
                                    <p className="text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco duis laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.</p>
                                    <span className="author__name">John Mack</span>
                                    <span className="author__job">Summer</span>
                                </div>
                                <div className="swiper-slide">
                                    <p className="text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco duis laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.</p>
                                    <span className="author__name">George Tim</span>
                                    <span className="author__job">Winter</span>
                                </div>
                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div> */}
                </section>

                <Helmet>
                    <script src="assets/js/swiper-bundle.min.js" type="text/javascript" />
                </Helmet>
            </>
        )
    }
}


export default About;