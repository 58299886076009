import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import $ from 'jquery';
import { Helmet } from "react-helmet";
import Gallery1 from '../../assets/images/gallery1.jpg'
import Gallery2 from '../../assets/images/gallery2.jpg'
import Gallery3 from '../../assets/images/r.gif'
import Gallery4 from '../../assets/images/Electric-Storm-High-64k.mp4'
import Gallery5 from '../../assets/images/gallery5.jpg'
import Gallery6 from '../../assets/images/skate2.gif'
import TheLastShawarma from '../../assets/images/The-Last-Shawarma.mp4'
import HomeVideo from '../../assets/images/homevideo.mp4'

class Home extends Component {
    src_path = window.src_path;
    constructor() {
        super()
        console.log(this.src_path)
        this.state = {
            src_path: window.src_path,
            server_path: window.server_path,
            galleryRoute: window.homepageRoute.gallery,
            gallery: [
                {
                    title: 'GOAT',
                    content: '',
                    path: Gallery1,
                    href: 'goat',
                    caption: 'Beeple & Corridor Studio Collab',
                    type: 'img',
                },
                {
                    title: 'Eardley',
                    content: '',
                    path: Gallery2,
                    href: 'eardley',
                    caption: 'Trevor Jones',
                    type: 'img',
                },
                {
                    title: 'Rain Village',
                    content: '',
                    path: Gallery3,
                    href: 'rain-village',
                    caption: 'SeerLight',
                    type: 'img',
                },
                {
                    title: 'An Electric Storm',
                    content: '',
                    path: Gallery4,
                    href: 'an-electric-storm',
                    caption: 'Reuben Wu',
                    type: 'video',
                },
                {
                    title: 'Universe Edge',
                    content: '',
                    path: Gallery5,
                    href: 'universe-edge',
                    caption: 'Six n Five',
                    type: 'img',
                },
                {
                    title: 'Skateboard Rides You',
                    content: '',
                    path: Gallery6,
                    href: 'skateboard-rides-you',
                    caption: 'Deekaymotion',
                    type: 'img',
                },
            ]
        }
    }



    loadInitJs() {

        /* setTimeout(function() { $("#page-loader").fadeOut(1500); }, 3000); */
        window.onscroll = function () { myFunction() };
        var cranavbar = document.getElementById("cranavbar");

        var crasticky = cranavbar.offsetTop;
        function myFunction() {
            if (window.pageYOffset >= crasticky) {
                cranavbar.classList.add("crasticky")
            } else {
                cranavbar.classList.remove("crasticky");
            }
        }

    }

    clickMenu() {
        $("#page-loader").show();
        window.scrollTo(0, 0);
        setTimeout(function () { $("#page-loader").fadeOut(1500); }, 3000);
        $(".qodef-follow-info-holder").remove();
        $("body").removeClass('sidebar_active');
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <main className="home">
                    {/*  <section className="sticky__hero" style={ {backgroundImage: `url(${main_slide})`} }> */}
                    <section className="sticky__hero vidSec">
                        <video src={HomeVideo} autoPlay muted loop playsInline controlsList="nodownload" preload="metadata"></video>

                        <div className="sound_toggle">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="volume-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-volume-up fa-w-18"><path fill="#fff" d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zm233.32-51.08c-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 78.98-39.55 152.09-105.82 195.58-11.17 7.32-14.29 22.34-6.95 33.5 7.04 10.71 21.93 14.56 33.51 6.95C528.27 439.58 576 351.33 576 256S528.27 72.43 448.35 19.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.54 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z" className=""></path></svg>
                        </div>

                        <div className="sticky__hero_inner">
                            <h1 className="hero__heading">Investing in the ‘possibilities’ of Web3</h1>
                            <Link onClick={() => this.clickMenu()} to="collection" className="css_btn view__more"><span>NFT Art Collection</span></Link>
                            <Link onClick={() => this.clickMenu()} to="/investments" className="css_btn view__more"><span>Web3 Investments</span></Link>
                        </div>
                        <Link onClick={() => this.clickMenu()} to="portfolio-item/prifma-luminosos" className="author_name">
                            <h2>prifma.luminosos</h2>
                            <span>GMUNK</span>
                        </Link>
                    </section>

                    <section className="galleryMain qodef-hover-animation--follow" id="cranavbar">
                        <div className="wrapper">
                            <div className="galleryMain-inner">



                                {
                                    this.state.gallery.map((value, index) => {
                                        return <div className="galleryBx">
                                            <div className="galleryBx-inner qodef-e-inner">
                                                <div className="galleryBx-image">


                                                    {value.type == 'img' ? (<Link onClick={() => this.clickMenu()} to={`/portfolio-item/${value.href}`} itemprop="url" >
                                                        <img src={`${value.path}`} />
                                                    </Link>

                                                    ) :
                                                        /* ( <video muted src={value.popupSrc} loop type="mp4"  ></video>) */
                                                        (<Link onClick={() => this.clickMenu()} to={`/portfolio-item/${value.href}`} itemprop="url" >
                                                            <video src={`${value.path}`} autoPlay muted loop playsInline controlsList="nodownload" preload="metadata" />
                                                        </Link>)

                                                    }
                                                </div>
                                                <div className="galleryBx-content">
                                                    <h4 itemprop="name" className="galleryBx-title">
                                                        <a itemprop="url" className="galleryBx-title-link"> {value.title} </a>
                                                    </h4>
                                                    <div className="galleryBx-category">
                                                        <a itemprop="url" className="galleryBx-category-link" >{value.caption}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    })
                                }







                            </div>
                        </div>
                    </section>


                    <section className="video__sec">
                        <video autoPlay loop muted>
                            <source src={TheLastShawarma} autoPlay muted loop playsInline controlsList="nodownload" preload="metadata" type="video/mp4" />
                        </video>

                        <Link onClick={() => this.clickMenu()} to="portfolio-item/the-last-shawarma" href="https://opensea.io/assets/0x1e6ebe5e7aa3cd50d0d7838ac70c46102e84b5fa/22200010001" className="author_name">
                            <h2>The Last Shawarma</h2>
                            <span>Bosslogic</span>
                        </Link>
                    </section>

                    <Helmet>
                        <script src="assets/js/amoli-core.js" type="text/javascript" />
                        <script src="assets/js/video_control.js" type="text/javascript" />
                    </Helmet>




                </main>



            </>
        )
    }
}

export default Home;